import React from "react";
// import { Counter } from "./features/counter/Counter";
import "./App.css";
// import BackToTop from "./container/CreditApplicationContainer/CreditApplicationContainer";
import TopBar from "./components/TopBar/TopBar";
import { CssBaseline } from "@material-ui/core";
import CreditApplicationContainer from "./container/CreditApplicationContainer/CreditApplicationContainer";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import ValidationContainer from "./container/ValidationContainer/ValidationContainer";
import NoActiveValidation from "./components/NoActiveValidation/NoActiveValidation";
import ExpiredToken from "./components/ExpiredToken/ExpiredToken";
import InvalidToken from "./components/InvalidToken/InvalidToken";
import ApplicationProcessSuccess from "./components/ApplicationProcessSuccess/ApplicationProcessSuccess";
import ApplicationProcessError from "./components/ApplicationProcessError/ApplicationProcessError";

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <TopBar />
      <Router>
        <Switch>
          <Route exact path="/" component={CreditApplicationContainer} />
          <Route exact path="/validation" component={ValidationContainer} />
          <Route
            exact
            path="/no-active-validation"
            component={NoActiveValidation}
          />
          <Route
            exact
            path="/application-process-error"
            component={ApplicationProcessError}
          />
          <Route exact path="/expired-token" component={ExpiredToken} />
          <Route exact path="/invalid-token" component={InvalidToken} />
          <Route
            exact
            path="/application-process-success"
            component={ApplicationProcessSuccess}
          />
          <Redirect to="/" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
