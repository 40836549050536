import axios from "axios";
import { baseURL } from "../constant";

export const axiosInst = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInst.interceptors.response.use(
  (response) => response,
  (error) => {
    // Do something with response error
    console.error(error.response);
    if (error.response.status === 400) {
      const { message } = error.response.data;
      if (message?.includes("expired")) {
        window.location.replace("/expired-token");
      }
      if (message?.includes("expired")) {
        window.location.replace("/expired-token");
      }
    }
    if (error.response.status === 401) {
      window.location.replace("/invalid-token");
    }
    return Promise.reject(error);
  }
);
