import { Toolbar, Typography, AppBar } from "@material-ui/core";
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      flexGrow: 1,
      textAlign: "center",
    },
  })
);

const TopBar = () => {
  const classes = useStyles();
  return (
    <AppBar color="default" position="relative">
      <Toolbar className="text-center">
        <img
          src="https://s3-us-west-2.amazonaws.com/swell-general/logos/logo-full.svg"
          height="42"
          alt="Logo"
        />
        <Typography variant="h6" className={classes.title}>
          HOMEOWNER CREDIT APPLICATION
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
