const BACK_END_API = {
  development: "https://wave2-backend.dev.swellenergy.com",
  sandbox: "https://sbx-wave2-backend.swellenergy.com",
  qa: "https://wave2-backend.qa.swellenergy.com",
  uat: "https://wave2-backend.uat.swellenergy.com",
  production: "https://prd-wave2-backend.swellenergy.com",
  demo: "https://wave2-backend.demo.swellenergy.com",
  local: "http://localhost:3001",
};

const getWQSUrl = () => {
  // Use WQS_URL env if available
  // This is for new AWS Architecture
  if (process.env.REACT_APP_WQS_URL) {
    return new URL(process.env.REACT_APP_WQS_URL).origin;
  }

  return new URL(
    // @ts-ignore
    BACK_END_API[process.env.REACT_APP_API_MODE] || BACK_END_API.local
  ).origin;
};

export const baseURL = getWQSUrl();
