import { Container, makeStyles, Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { Constants } from "../../container/CreditApplicationContainer/constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "30px",
    padding: theme.spacing(2),
  },
}));

const ApplicationProcessError = () => {
  const classes = useStyles();

  return (
    <Container>
      <Paper className={classes.paper}>
        <Typography align="center">
          {Constants.GENERIC_ERROR}
        </Typography>
        <Typography align="center">
          {Constants.GENERIC_ERROR_ACTION}
        </Typography>
      </Paper>
    </Container>
  );
};

export default ApplicationProcessError;
