import { Container, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "30px",
    padding: theme.spacing(2),
  },
}));

const ExpiredToken = () => {
  const classes = useStyles();

  return (
    <Container>
      <Paper className={classes.paper}>
        <Typography align="center">
          Your Credit Qualification request has expired.
        </Typography>
        <Typography align="center">
          Please contact Swell Customer Support for assitance.
        </Typography>
      </Paper>
    </Container>
  );
};

export default ExpiredToken;
