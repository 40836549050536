export const AGREEMENT_TERM_USE_CASE_1 =`<div>Swell Energy Inc. ("Swell") is the potential installment seller or lessor of your System. By checking this box and starting your
application, you: (1) agree to [Consent to Electronic Receipt and/or Execution of Certain Contracts and Related Notices and
Disclosures] <a href="https://www.swellenergy.com/esign_consent" target="_blank">https://www.swellenergy.com/esign_consent</a> (2) agree to Swell's [Arbitration Agreement]
 <a href="https://www.swellenergy.com/arbitration_agreement" target="_blank">https://www.swellenergy.com/arbitration_agreement</a> which includes a class action waiver in the event of any dispute between you
and Swell and/or your installer; (3) agree to Swell's [Privacy Policy] <a href="https://www.swellenergy.com/privacy-policy" target="_blank"> https://www.swellenergy.com/privacy-policy </a> and (4) agree
that Swell may obtain one or more credit reports on you and confirm information you provide in connection with your credit or lease
application. You may opt out of the Arbitration agreement by following the steps set forth in the Arbitration Agreement.</div>`;

export const INFO_LABEL = `At least 1 applicant on every credit application must be both approved for credit and verified as on title to the property where the
energy equipment will be installed. Swell may void your contract if Swell determines that no one that is approved for credit is on
title to the property where the energy equipment will be installed.`;


export const AGREEMENT_TERM_USE_CASE_2 = `By checking this box, I intend to apply for credit jointly with another person and I understand that I may be asked to provide information about the joint applicant such as name, phone number, and email address.`;