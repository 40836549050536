import React, { FormEvent, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { isValid } from "date-fns";
import ApplicantInfo from "../../components/ApplicantInfo/ApplicantInfo";
import Acknowledgement from "../../components/Acknowledgement/Acknowledgement";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  CircularProgress,
} from "@material-ui/core";
import {
  IAcknowledgement,
  IApplicantData,
  IApplyCreditQualificationRequest,
  IIncome,
  IAddress,
  ISSN,
  IAuthorizedTimeStamp
} from "./typing";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  applyCreditQualification,
  selectIsLoading,
  selectNewJWTToken,
  selectOpportunityId,
  selectPrimaryApplicantData,
  selectInstallationAddress,
  selectQualificationCreditId,
  selectHasCoApplicant,
  selectContactId
} from "./CreditApplicationSlice";
import { ValidatorForm } from "react-material-ui-form-validator";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  mb2: {
    marginBottom: "20px",
  },
}));

const INIT_PRIMARY_APPLICANT_DATA: IApplicantData = {
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  phoneNumber: "",

};

const INIT_ADDRESS: IAddress={
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zipcode: ""
}

const INIT_SNN: ISSN = {
  a: undefined,
  b: undefined,
  c: undefined,
} as any;

const INIT_INCOME: IIncome = {
  incomeFrequency: undefined,
  individualIncome :undefined
} as any;

const INIT_ACKNOWLEDGEMENT:IAcknowledgement ={
  agreement_term_1_checked_at: undefined,
  joint_intention_disclosure_accepted_at:undefined
}  as any;

const CreditApplicationContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoading = useSelector(selectIsLoading);
  const primaryApplicantDataRedux = useSelector(selectPrimaryApplicantData);
  const qualificationCreditId = useSelector(selectQualificationCreditId);
  const opportunityId = useSelector(selectOpportunityId);
  const newJWTToken = useSelector(selectNewJWTToken);
  const hasCoApplicant = useSelector(selectHasCoApplicant);
  const contactId= useSelector(selectContactId);
  const installationAddressDataRedux = useSelector(selectInstallationAddress);

  const formRef: React.LegacyRef<ValidatorForm> | undefined = useRef(null);
  const [primaryApplicantData, setPrimaryApplicantData] =
    useState<IApplicantData>(INIT_PRIMARY_APPLICANT_DATA);

  const [primaryResidence, setPrimaryResidence] = useState<IAddress>(INIT_ADDRESS);

  const [installationAddress, setInstallationAddress] = useState<IAddress>(INIT_ADDRESS);

  const [income, setIncome] = useState<IIncome>(INIT_INCOME);

  const [agreementTermCheckedAt,setAgreementTermCheckedAt ] = useState<IAcknowledgement>(INIT_ACKNOWLEDGEMENT);

  const [authorizedTimeStamp,setAuthorizedTimeStamp ] = useState<IAuthorizedTimeStamp>({credit_check_authorized_at:undefined} as any);

  const [primaryApplicantBirthDay, setPrimaryApplicantBirthDay] = useState<Date | null>(null);

  const [primaryApplicantSNN, setPrimaryApplicantSNN] =
    useState<ISSN>(INIT_SNN);

  const [primaryApplicantConfirmSNN, setPrimaryApplicantConfirmSNN] =
    useState<ISSN>(INIT_SNN);

       
  const handleAuthorizeButtonClick = () => {
    setAuthorizedTimeStamp({
      ...authorizedTimeStamp,
      credit_check_authorized_at: new Date().toISOString()
    });
  }
  
  const handleFormSubmit = (e: FormEvent<Element>) => {
    e.preventDefault();

    if (!primaryApplicantBirthDay || !isValid(primaryApplicantBirthDay)) {
      return;
    }

    const postData: IApplyCreditQualificationRequest = {
      qualificationCreditId,
      opportunityId,
      authenticationToken: newJWTToken,
      contactId: contactId,
      applicant:{firstName: primaryApplicantData.firstName,
         middleName: primaryApplicantData.middleName,
         lastName: primaryApplicantData.lastName, 
         email: primaryApplicantData.email, 
         phoneNumber: primaryApplicantData.phoneNumber},
        applicantSecuredData: {
        soc: primaryApplicantSNN.a + primaryApplicantSNN.b + primaryApplicantSNN.c,
        dob: primaryApplicantBirthDay.toISOString().slice(0, 10),
         incomeFrequency: income.incomeFrequency,
         individualIncome: income.individualIncome
      },
      primaryResidence: primaryResidence,
      installationAddress:{
        addressLine1: installationAddress.addressLine1,
        addressLine2: installationAddress.addressLine2,
        city: installationAddress.city,
         zipcode : installationAddress.zipcode,
        state: installationAddress.state
      },
      acknowledgement: { agreement_term_1_checked_at: agreementTermCheckedAt.agreement_term_1_checked_at,
      credit_check_authorized_at : authorizedTimeStamp!.credit_check_authorized_at,
      joint_intention_disclosure_accepted_at: agreementTermCheckedAt.joint_intention_disclosure_accepted_at
        
      }

    };
    dispatch(applyCreditQualification(postData));
  };

  const handleFormError = (errors: any[]) => {
    const firstErrorElementId = errors[0].props.id;
    if (!firstErrorElementId) return;

    const firstErrorElement = document.getElementById(firstErrorElementId);
    if (!firstErrorElement) return;
    firstErrorElement.focus();
  };
  
  useEffect(() => {
    if (primaryApplicantDataRedux) {
      setPrimaryApplicantData((current) => ({
          ...current,
        ...primaryApplicantDataRedux,
      }));
    }
  }, [primaryApplicantDataRedux]);

  useEffect(()=>{
    if(installationAddressDataRedux){
      setInstallationAddress((current) => ({
        ...current,
      ...installationAddressDataRedux,
      }));
    }
  }, [installationAddressDataRedux])
  

  useEffect(() => {
    if (!newJWTToken) {
      history.replace("/no-active-validation");
    }
  }, [newJWTToken, history]);

  useEffect(() => {
    ValidatorForm.addValidationRule(
      "isPrimarySsnAMatch",
      (value) => value === primaryApplicantSNN.a
    );

    ValidatorForm.addValidationRule(
      "isPrimarySsnBMatch",
      (value) => value === primaryApplicantSNN.b
    );

    ValidatorForm.addValidationRule(
      "isPrimarySsnCMatch",
      (value) => value === primaryApplicantSNN.c
    );

    ValidatorForm.addValidationRule(
      "isPrimarySsnWholeMatch",
      (value) =>
        value ===
        primaryApplicantSNN.a + primaryApplicantSNN.b + primaryApplicantSNN.c
    );

    return () => {
      ValidatorForm.removeValidationRule("isPrimarySsnAMatch");
      ValidatorForm.removeValidationRule("isPrimarySsnBMatch");
      ValidatorForm.removeValidationRule("isPrimarySsnCMatch");
      ValidatorForm.removeValidationRule("isPrimarySsnWholeMatch");
      ValidatorForm.removeValidationRule("isCoSsnAMatch");
      ValidatorForm.removeValidationRule("isCoSsnBMatch");
      ValidatorForm.removeValidationRule("isCoSsnCMatch");
      ValidatorForm.removeValidationRule("isCoSsnWholeMatch");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryApplicantSNN]);

  return (
    <Container>
      <Paper className={classes.paper}>
        <ValidatorForm
          ref={formRef}
          onSubmit={handleFormSubmit}
          onError={handleFormError}
        >
          <ApplicantInfo
            formRef={formRef}
            confirmSSN={primaryApplicantConfirmSNN}
            setConfirmSSN={setPrimaryApplicantConfirmSNN}
            birthDay={primaryApplicantBirthDay}
            setBirthDay={setPrimaryApplicantBirthDay}
            SSN={primaryApplicantSNN}
            setSSN={setPrimaryApplicantSNN}
            setState={setPrimaryApplicantData}
            applicantData={primaryApplicantData}
            installationAddress={installationAddress}
            setInstallationAddress={setInstallationAddress}
            primaryResidence={primaryResidence}
            setPrimaryResidence={setPrimaryResidence}
            income= {income}
            setIncome={setIncome}
          />

          <Acknowledgement 
           acknowledgementData ={agreementTermCheckedAt}
           setState={setAgreementTermCheckedAt}
           hasCoApplicant = {hasCoApplicant}
          />

          <Grid container justify="center">
            <Button
              type="submit"
              className={classes.mb2}
              variant="contained"
              color="primary"
              endIcon={!isLoading && <Icon>send</Icon>}
              disabled={isLoading}
              onClick={handleAuthorizeButtonClick}
            >
              {isLoading ? (
                <CircularProgress size={25} />
              ) : (
                "Yes, I Authorize My Credit Check"
              )}
            </Button>
            <Grid item xs={12} md={12}>
              <Typography align="center">
                NOTE To the Swell Team : Please ensure this application is
                filled and submitted only by the customer.
              </Typography>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Paper>
    </Container>
  );
};

export default CreditApplicationContainer;