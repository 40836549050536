import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { AGREEMENT_TERM_USE_CASE_1,AGREEMENT_TERM_USE_CASE_2, INFO_LABEL } from "../../constant";
import { IAcknowledgement } from "../../container/CreditApplicationContainer/typing";
import parse from 'html-react-parser';

const useStyles = makeStyles({
  mb2: {
    marginBottom: "20px",
  },

  ml2:{
    marginLeft: "30px"
  }
});

interface IAcknowledgementInfo {
  acknowledgementData: IAcknowledgement;
  setState: React.Dispatch<React.SetStateAction<IAcknowledgement>>;
  hasCoApplicant: boolean;
}

const Acknowledgement = ({
  acknowledgementData,
  setState,
  hasCoApplicant
}: IAcknowledgementInfo) => {
  const classes = useStyles();
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, acknowledgmentType:number) => {
    if (acknowledgmentType ===1 &&  e.target.checked) {
        setState({
          ...acknowledgementData,
          agreement_term_1_checked_at: new Date().toISOString()
        });
      return;
    }
   if(acknowledgmentType ===1 &&  !e.target.checked) {
      setState({
        ...acknowledgementData,
        agreement_term_1_checked_at: ""
      });
      return;
    }
    if(acknowledgmentType ===2  &&  e.target.checked){
      setState({
        ...acknowledgementData,
        joint_intention_disclosure_accepted_at: new Date().toISOString()
      });
    return;
  }
   if(acknowledgmentType ===2 &&  !e.target.checked) {
    setState({
      ...acknowledgementData,
      joint_intention_disclosure_accepted_at: ""
    });
  return;
  }
}

  return (
    <>
      <Grid className={classes.mb2} container justify="center" spacing={3}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h6" align="center">
            Acknowledgements
          </Typography>
        </Grid>
        <Grid item xs={12} md={10} >
          <FormControlLabel
            control={<Checkbox required name="checkedB" color="primary" onChange={(e) => handleOnChange(e,1)}
          /> }
            label  = { parse(AGREEMENT_TERM_USE_CASE_1)}
          />
          </Grid>
          { hasCoApplicant && <Grid item xs={12} md={10}>
          <FormControlLabel
            control={<Checkbox required name="checkedB" color="primary" onChange={(e) => handleOnChange(e,2)}
            />}
            label ={AGREEMENT_TERM_USE_CASE_2}
          />
        </Grid>}
        <Grid item xs={12} md={10}>
           <Typography className={classes.ml2} >{INFO_LABEL}</Typography>
          </Grid>
      </Grid>
    </>
  );
};

export default Acknowledgement;
