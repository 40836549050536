import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getApplicantDetail,
  selectPrimaryApplicantData,
  creditApplicationErrorSelector,
} from "../CreditApplicationContainer/CreditApplicationSlice";

const ValidationContainer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const token = location.search?.split("?s=")[1];

  const creditApplicationError = useSelector(creditApplicationErrorSelector);
  const primaryApplicantData = useSelector(selectPrimaryApplicantData);

  useEffect(() => {
    if (!token) return;
    dispatch(getApplicantDetail({ token }));
  }, [token, dispatch]);

  useEffect(() => {
    if (primaryApplicantData) {
      history.replace("/");
    }
  }, [primaryApplicantData, history]);

  return !!creditApplicationError ? (
    <>
      <br />
      <h1 className="text-center text-error">Error!</h1>
      <p
        style={{
          fontSize: "1rem",
        }}
        className="text-center"
      >
        {typeof creditApplicationError === "string"
          ? creditApplicationError
          : creditApplicationError.message}
      </p>
    </>
  ) : null;
};

export default ValidationContainer;
