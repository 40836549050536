export enum EMilestoneStatus {
  INITIATED = "Initiated",
  VERBAL_CONSENT = "Verbal Consent",
  APPLICATION_EMAILED = "Application Emailed",
  APPLICATION_STATUS = "Application Status",
}

export enum EProcessStatus {
  INITIATED = "INITIATED",
  STARTED = "STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
  APPLICATION_EMAILED = "APPLICATION EMAILED",
  VERBAL_CONSENT_ACCEPTED = "VERBAL CONSENT - ACCEPTED",
  VERBAL_CONSENT_DECLINED = "VERBAL CONSENT - DECLINED",
  COMPLETE_APPLICANT_DECLINED_VERBAL_CONSENT = "COMPLETE - APPLICANT DECLINED VERBAL CONSENT",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  ERROR = "ERROR",
}

export enum Constants {
  GENERIC_ERROR = "There was an error processing your request. Please try again.",
  GENERIC_ERROR_ACTION = "If you are still having issues, contact your sales agent."
}