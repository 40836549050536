import { Container, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "30px",
    padding: theme.spacing(2),
  },
}));

const NoActiveValidation = () => {
  const classes = useStyles();

  return (
    <Container>
      <Paper className={classes.paper}>
        <Typography align="center">
          We are unable to find any pending credit approval requests for you.
        </Typography>
        <Typography align="center">
          If you believe this is not accurate, please contact Swell Customer
          Support for assistance.
        </Typography>
      </Paper>
    </Container>
  );
};

export default NoActiveValidation;
