import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../app/store";
import { axiosInst } from "../../services";
import {
  ICreditApplicationState,
  IGetApplicationDetailsRequest,
  IGetApplicationDetailsResponse,
  IApplyCreditQualificationRequest,
} from "./typing.d";
import { EProcessStatus } from "./constants";

const devState = window.localStorage.getItem("devState");

const initialState: ICreditApplicationState = (process.env.NODE_ENV ===
  "development" &&
  devState &&
  JSON.parse(devState)) || {
  isLoading: false,
  primaryApplicantData: null,
  installationAddress: "",
  qualificationCreditId: "",
  opportunityId: "",
  processStatus: undefined,
  responseStatus: undefined,
  newJWTToken: "",
  error: null,
};

export const creditApplicationSlice = createSlice({
  name: "CreditApplication",
  initialState,
  reducers: {
    updateIsLoading: (
      state: ICreditApplicationState,
      action: PayloadAction<boolean>
    ) => {
      const { payload } = action;
      state.isLoading = payload;
    },

    getApplicantDetailFail: (
      state: ICreditApplicationState,
      action: PayloadAction<any>
    ) => {
      state.error = action.payload;
    },

    getApplicantDetailSuccess: (
      state: ICreditApplicationState,
      action: PayloadAction<IGetApplicationDetailsResponse>
    ) => {
      const { payload } = action;
      state.qualificationCreditId = payload.qualificationCreditId;
      state.opportunityId = payload.opportunityId;
      state.primaryApplicantData = payload.primaryApplicantData;
      state.processStatus = payload.processStatus;
      state.responseStatus = payload.responseStatus;
      state.newJWTToken = payload.newJWTToken;
      state.contactId = payload.contactId;
      state.hasCoApplicant = payload.hasCoApplicant;
      state.installationAddress = payload.installationAddress;
      state.error = null;
      if (process.env.NODE_ENV === "development") {
        window.localStorage.setItem("devState", JSON.stringify(state));
      }
    },
  },
});

export const {
  getApplicantDetailSuccess,
  getApplicantDetailFail,
  updateIsLoading,
} = creditApplicationSlice.actions;

export const getApplicantDetail =
  (payload: IGetApplicationDetailsRequest): AppThunk =>
    async (dispatch) => {
      try {
        const data = await axiosInst.post("qualifications/applications", payload);

        if (data.status !== 400) {
          const { processStatus } = data.data.data;

          switch (processStatus) {
            case EProcessStatus.INITIATED:
            case EProcessStatus.STARTED:
            case EProcessStatus.APPLICATION_EMAILED: {
              dispatch(getApplicantDetailSuccess(data.data.data));
              return;
            }
            case EProcessStatus.COMPLETED:
            case EProcessStatus.IN_PROGRESS:
            case EProcessStatus.PENDING: {
              window.location.replace("/application-process-success");
              return;
            }
            default:
              window.location.replace("/no-active-validation");
          }
        }
      } catch (error: any) {
        dispatch(
          getApplicantDetailFail(
            error?.response?.data || "Cannot Get Application Detail"
          )
        );
      }
    };

export const applyCreditQualification = (
  payload: IApplyCreditQualificationRequest
): AppThunk => async (dispatch) => {
  try {
    dispatch(updateIsLoading(true));
    const data = await axiosInst.post(
      "/qualifications/apply-credit-qualification",
      payload
    );
    dispatch(updateIsLoading(false));
    if (
      data.status !== 400 &&
      data.data.data.responseStatus === "APPLICATION_PROCESS_SUCCESS"
    ) {
      window.location.replace("/application-process-success");
    } else {
      window.location.replace("/no-active-validation");
    }
  } catch (error: any) {
    window.location.replace("/application-process-error");
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectPrimaryApplicantData = (state: RootState) =>
  state.creditApplication.primaryApplicantData;

export const selectQualificationCreditId = (state: RootState) =>
  state.creditApplication.qualificationCreditId;

export const selectOpportunityId = (state: RootState) =>
  state.creditApplication.opportunityId;

export const selectNewJWTToken = (state: RootState) =>
  state.creditApplication.newJWTToken;

export const selectIsLoading = (state: RootState) =>
  state.creditApplication.isLoading;

export const selectHasCoApplicant = (state: RootState) =>
  state.creditApplication.hasCoApplicant;

export const selectContactId = (state: RootState) =>
  state.creditApplication.contactId;

export const selectInstallationAddress = (state: RootState) =>
state.creditApplication.installationAddress;

export const creditApplicationErrorSelector = (state: RootState) =>
  state.creditApplication.error;

export default creditApplicationSlice.reducer;
